import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {Col, Container, Row} from "react-bootstrap";
import './style.scss'
import styles from "../Hero/Hero.module.sass";
import {Link} from "react-router-dom";

function Hero() {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        autoplaySpeed: 7000,
        autoplay: true,
        slidesToScroll: 1,
        className: 'liiHeroSlider',
        appendDots: (dots) => (
            <div style={{bottom: '60px'}}>
                <ul className="custom-dots">{dots}</ul>
            </div>
        ),
    };

    const jsonData =
        [
            // {
            //     "title": `DALIA MBARAK<br/>Exclusive Collection`,
            //     "description": "A visionary artist weaving tales through pixels, her NFT collection a testament to boundless creativity and digital innovation.",
            //     "image": "dalia.png",
            // },
            {
                "title": `Ashanik By Sillawy`,
                "description": "Illuminate Your Space with the Exclusive Siilawy LED Acrylic Frame",
                // "video": "/videos/sillawy_coming_soon.mp4",
                "image": "/images/artists/led-frame.jpeg",
                'cta':{
                    'label': 'Buy Now',
                    'href': '/item/siilawy-frame-001'
                }
            },
            // {
            //     "title": `Ashanik By Sillawy`,
            //     "description": "Illuminate Your Space with the Exclusive Siilawy LED Acrylic Frame",
            //     "image": "/images/artists/led-frame.jpeg",
            //     'cta':{
            //         'label': 'Buy Now',
            //         'href': '/item/siilawy-frame-001'
            //     }
            // }
            // {
            //     "title": `Claim the <span>Digital</span> Collectible of Your Favorite <span>Celebrity</span>.`,
            //     "description": "Taking you on a trip to the future: Explore what celebrities are up to in 2080.",
            //     "video": '/videos/lii_landing_1280_continues.mp4',
            //     'cta':{
            //         'label': 'Explore Now',
            //         'href': '/explore/'
            //     }
            // }
        ]

    return (
        <div style={{
            minHeight: '100vh',
            position: 'relative',
            // paddingTop: '90px',
            paddingBottom: 0,
            overflow: 'hidden'
        }}>

            <Slider {...sliderSettings}>

                {jsonData.map((item, index) => (
                    <div key={index} className="slide h-100">
                        <Container fluid style={{
                            backgroundImage: `url(${item.image})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center right',
                            position: 'relative'
                        }}>
                            {item.video && <video controlsList="nodownload" id={"heroVideo"}
                                                  loop="true"
                                                  autoPlay="autoplay"
                                                  muted
                                                  playsInline>
                                <source src={item.video}/>
                            </video>}

                            <Container className={'h-100'} style={{
                                position: 'relative',
                                zIndex: 2
                            }}>
                                <Row className={'d-flex align-items-center'} style={{zIndex: 2, minHeight: '88vh'}}>
                                    <Col lg={{span: 7, order: 1}}>
                                        <h1 dangerouslySetInnerHTML={{__html: item.title}}>
                                        </h1>
                                        <p>{item.description}</p>
                                        {item.cta &&
                                            <a href={item.cta.href} className={'sliderCTA'}>{item.cta.label}</a>
                                        }
                                    </Col>
                                    {/*<Col lg={{span: 6, order: 2}} className="image-container">*/}
                                    {/*    <img src={'/images/dalia.png'} className={'img-fluid'}/>*/}

                                    {/*</Col>*/}
                                </Row>
                            </Container>
                        </Container>
                    </div>
                ))}

            </Slider>
            {/*<Link to="/search01/all" className={styles.emblem}>*/}
            {/*    <img src="/images/content/emblem.svg" alt="pic"></img>*/}
            {/*</Link>*/}

        </div>
    )
}

export default Hero;
