import React from "react";
import styles from "./VerifyKYCButton.module.sass";
import useSumsubWebSDK from "../../customHooks/useSumsubWebSDK";
import {

  PopUpAlert,
} from "../../controller/utils";
import { axiosInstance } from "../../utils/API";
import { useDispatch } from "react-redux";
import { setBasicAccountDetails } from "../../redux/accountSlice";

function SumSubWidget({ fetchNewAccessTokenFromBackend, accessToken }) {

  const dispatch = useDispatch();
  const getConfig = () => {
    if (!localStorage.getItem("userToken")) {
      //PopUpAlert("Alert", "No wallet connection found", "error");
      return;
    }
    let config = {
      headers: {
        Authorization: localStorage.getItem("userToken"),
      },
    };
    return config;
  };

  const getProfileDetails = async () => {
    const config = getConfig();
    const profileDetails = await axiosInstance
      .get(`user/get-profile`, config)
      .then((res) => {
        const {
          id,
          profilePicUrl,
          email,
          firstName,
          lastName,
          userName,
          status,
          loginWith,
          kycStatus,
          isKYCLimitExceeded,
          isCreatedByAdmin,
          emailVerified,
        } = res.data?.result;

        let payload = {
          email: email,
          firstName: firstName,
          lastName: lastName,
          profilePicture: profilePicUrl,
          userName: userName,
          status: status,
          loginType: loginWith,
          id: id,
          kycStatus: kycStatus,
          isKYCLimitExceeded: isKYCLimitExceeded,
          emailVerified: emailVerified,
          isCreatedByAdmin: isCreatedByAdmin,
        };
        dispatch(setBasicAccountDetails(payload));
        return payload;
      })
      .catch(() => {
        return false;
      });

    return profileDetails;
  };


  console.log("sumsub.hitWidget", accessToken);
  useSumsubWebSDK(fetchNewAccessTokenFromBackend, accessToken, getProfileDetails);

  return (
    <>
      <div className = {styles.sumsub}id="sumsub-websdk-container"></div>
    </>
  );
}

export default SumSubWidget;
