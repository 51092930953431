import {Col, Container, Row} from "react-bootstrap";

import './style.scss'
import {useState, useEffect} from "react";
import validator from "validator";
import {PopUpAlert} from "../../../controller/utils";
import axios from "axios";

function Connect() {

    const [email, setEmail] = useState("");
    const [subscribeStatus, setSubscribeStatus] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('subscriptionStatus')) {

            setSubscribeStatus(true)
        }

    }, [])

    const validateEmail = (email) => {
        let isValid;
        isValid = String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

        isValid = email?.split(".")[email.split(".")?.length - 1]?.length > 1;
        return isValid;
    };

    const handleSub = (e) => {
        e.preventDefault();
        if (!validateEmail(email) || !validator.isEmail(email)) {
            PopUpAlert("Alert", "Please enter a valid email.", "error");
            return false;
        }
        if (email?.length > 1) {
            axios
                .post(process.env.REACT_APP_EMAIL_NEWSLETTER, {email})
                .then((res) => {
                    setSubscribeStatus(true)
                    localStorage.setItem('subscriptionStatus', true)
                    PopUpAlert("Great", "Thank you for your subscription", "success");
                    setEmail('')
                })
                .catch((err) => {
                    PopUpAlert("Alert", err.response.data.message, "error");
                });
        } else {
            PopUpAlert("Alert", "Please enter your email", "error");
        }
    };

    return (
        <Container className={'my-5 mt-md-0'}>
            <Row>
                <Col md={6} className={'connectLeft d-flex align-items-center flex-column justify-content-center'}>
                    <h3>Connect,
                        Subscribe, Stay
                        Informed</h3>
                    <p>Engage with us on social
                        media, join our vibrant
                        community, and be part
                        of every conversation.
                        Don't miss a single
                        moment - stay connected with our updates!</p>
                    {/*<button className={'d-md-block d-none lii-view-more d-flex align-items-center my-2'}>Join Discord <img src={'/Icons/two-dots.svg'} /></button>*/}
                </Col>
                <Col md={6} className={'connectRight'}>
                    <div id={'newsletterBox'}>
                        <img src="/images/robot.png" className={'img-fluid'}/>
                        <p>Subscribe to our newsletter and stay tuned to
                            our latest updates</p>

                        {subscribeStatus ?
                            <div className={'text-primary'}>
                                You have successfully subscribed to the newsletter
                            </div> :
                            (
                                <>
                                    <div className={'w-100 d-flex flex-row align-items-center justify-content-center'}
                                         style={{
                                             borderBottom: '2px solid #F2F6F9'
                                         }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                             viewBox="0 0 24 24"
                                             fill="none">
                                            <path
                                                d="M22.9578 6L15.2413 13.715C14.3809 14.573 13.2153 15.0549 12 15.0549C10.7847 15.0549 9.61908 14.573 8.75867 13.715L1.04217 6C1.02933 6.1448 1 6.27586 1 6.41975V17.4176C1.00146 18.6325 1.48481 19.7972 2.34403 20.6562C3.20326 21.5153 4.3682 21.9985 5.58333 22H18.4167C19.6318 21.9985 20.7967 21.5153 21.656 20.6562C22.5152 19.7972 22.9985 18.6325 23 17.4176V6.41975C23 6.27586 22.9707 6.1448 22.9578 6Z"
                                                fill="#F2F6F9"/>
                                            <path
                                                d="M13.8852 12.2239L22 4.13492C21.6069 3.48525 21.0525 2.94751 20.3902 2.57356C19.7279 2.19961 18.9801 2.00207 18.2189 2H5.78109C5.01992 2.00207 4.27212 2.19961 3.60983 2.57356C2.94754 2.94751 2.3931 3.48525 2 4.13492L10.1148 12.2239C10.6155 12.7209 11.2933 13 12 13C12.7067 13 13.3845 12.7209 13.8852 12.2239Z"
                                                fill="#F2F6F9"/>
                                        </svg>
                                        <input type={"text"} className={'form-control subscriptionEmail'}
                                               placeholder={'Enter you email to subscribe'}
                                               onChange={(e) => setEmail(e.target.value)}/>
                                    </div>

                                    <button onClick={handleSub}>
                                        Subscribe
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24"
                                             viewBox="0 0 25 24"
                                             fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M2.99291 1.31895C2.68727 1.16613 2.32064 1.20521 2.05409 1.41903C1.78753 1.63285 1.66984 1.98228 1.75272 2.31379L2.99127 7.268L4.17677 12.01L1.75272 21.7062C1.66984 22.0377 1.78753 22.3871 2.05409 22.6009C2.32064 22.8148 2.68727 22.8538 2.99291 22.701L22.8097 12.7926C23.1062 12.6444 23.2934 12.3414 23.2934 12.01C23.2934 11.6786 23.1062 11.3756 22.8097 11.2274L2.99291 1.31895ZM4.68902 6.84356L3.91188 3.73499L20.4619 12.01L3.91188 20.285L5.7643 12.8753H11.2618V11.1253H5.75946L4.68902 6.84356Z"
                                                  fill="#F2F6F9"/>
                                        </svg>
                                    </button>
                                </>
                            )}
                    </div>
                </Col>
            </Row>
        </Container>
    )

}

export default Connect;
