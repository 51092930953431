import './style.scss'
import {Col, Container, Row} from "react-bootstrap";

const About = () => {

    const features = [
        {
            title: '<br/>Officially Licensed Merchandise',
            desc: 'All merchandise is officially licensed, guaranteeing authenticity and a true representation of the artists\' brands.',
            icon: '/Icons/digital_assets.svg'
        },
        {
            title: '<br/>Guaranteed Authenticity',
            desc: 'We ensure authenticity and quality, offering fans verified merchandise that connects them to their favorite celebrities.',
            icon: '/Icons/secondary_market.svg'
        },
        {
            title: '<br/>Global Shipping',
            desc: 'Our marketplace offers reliable global shipping, ensuring easy access to authentic celebrity merchandise worldwide.',
            icon: '/Icons/hybrid_accessibility.svg'
        },
        {
            title: '<br/>Exclusive Collections',
            desc: 'Explore exclusive, unique merchandise created in collaboration with artists, offering fans one-of-a-kind products to cherish.',
            icon: '/Icons/user_friendly.svg'
        }

    ]

    return <Container>
            <Row >
                <Col md={{span: 7, order: 0}} xs={{order: 1}} className={'d-flex align-items-center'}>
                    <div id={'aboutHeader'} className={'my-md-5 mt-5 text-center ps-md-5'}>
                        <h2>Step into the future of <p>entertainment</p>  </h2>
                    </div>
                </Col>
                <Col md={{span: 4, order: 1, offset: 0}} xs={{order: 0, span: 6, offset: 3}} className={'text-center mt-5'}>
                    <img src={'/images/aboutus.png'} className={'img-fluid'}/>
                </Col>
            </Row>
            <Row className={'px-2 aboutFeatures my-5'}>
                <Col xs={12}>
                    <div className={'aboutFeature my-5 flex-column'}>
                        <h1>Marketplace Features</h1>
                        <p>Explore Lii's Features for Authentic Celebrity Merchandise and Exclusive Collections.</p>
                    </div>
                </Col>
                {features.map((f) => {
                    return <Col md={3} className={'my-4 my-md-0 p-0'}>
                        <div className={'gridSection'}>
                            <img src={f.icon} className={'img-fluid'}/>
                            <h2 className={'my-2'} dangerouslySetInnerHTML={{__html: f.title}}></h2>
                            <p dangerouslySetInnerHTML={{__html: f.desc}}></p>
                        </div>
                    </Col>
                })}
            </Row>
            <Row className={'aboutFeature my-5'}>
                <Col md={7}>
                    <h1 className={'my-2 text-md-start'}>
                        <img src={'/images/rocket.png'} width={50} className={'d-inline-block d-md-none'}/>
                        Mission
                    </h1>
                    <p className={'text-justify text-md-start'}>At Lii, our mission is to transform the entertainment industry by providing innovative solutions that enhance how celebrities and brands engage with their audiences. We are committed to simplifying the journey for both creators and fans, offering a platform that brings their interactions to life.</p>
                </Col>
                <Col md={5} className={'d-none d-md-block'}>
                    <img src={'/images/rocket.png'} className={'img-fluid'}/>
                </Col>
            </Row>
            <Row className={'aboutFeature my-5'}>
                <Col md={5} className={'d-none d-md-block'}>
                    <img src={'/images/telescope.png'} className={'img-fluid'}/>
                </Col>
                <Col md={7}>
                    <h1 className={'my-2 text-md-start'}>
                        <img src={'/images/telescope.png'} width={100} className={'d-inline-block d-md-none'}/>
                        Vision
                    </h1>
                    <p className={'text-justify text-md-start'}>At Lii, we envision a world where every fan can easily connect with their favorite celebrities through authentic merchandise that reflects their passion. We strive to be the leading marketplace that empowers artists by providing innovative tools for engagement while celebrating the unique bond between creators and their audiences. Our goal is to redefine the fan experience, making it easier for supporters to access exclusive products and fostering a vibrant community centered around creativity, authenticity, and collaboration.
                    </p>
                </Col>
            </Row>
            <Row className={'aboutFeature'}>
                <Col className={'d-block d-md-none'}>
                    <img src={'/images/empowerment.png'} className={'img-fluid'}/>
                </Col>
                <Col md={7}>
                    <h1 className={'my-2 text-start'}>Empowerment And Inclusion</h1>
                    <p className={'text-start'}>Empowerment and inclusion are at the heart of Lii. We are dedicated to driving positive change in the MENA region by enabling celebrities and brands to connect with fans in meaningful ways. Our platform opens up new revenue streams for creators and ensures that their intellectual property is protected, offering fair compensation for their work and creativity. Through Lii, fans gain unprecedented access to exclusive offerings, supporting their favorite celebrities and brands in unique and personal ways.
                        {/*<ul className={'p-5 p-lg-2'}>*/}
                        {/*    <li style={{*/}
                        {/*        listStyleImage: 'url(/images/check_icon.png)'*/}
                        {/*    }}> We revolutionize how celebrities and brands engage with their audience, offering unique*/}
                        {/*        opportunities for them to monetize through Web3, unlocking new revenue streams.*/}
                        {/*    </li>*/}
                        {/*    <li style={{*/}
                        {/*        listStyleImage: 'url(/images/check_icon.png)'*/}
                        {/*    }}>Our platform integrates a sophisticated royalty system, safeguarding the intellectual*/}
                        {/*        property of celebrities and brands, ensuring fair compensation for their work and*/}
                        {/*        creativity.*/}
                        {/*    </li>*/}
                        {/*    <li style={{*/}
                        {/*        listStyleImage: 'url(/images/check_icon.png)'*/}
                        {/*    }}>lii introduces a dynamic secondary market for buying and selling collectibles, enhancing*/}
                        {/*        the*/}
                        {/*        value of digital assets, and providing new avenues for celebrities and brands to connect*/}
                        {/*        with*/}
                        {/*        their fans.*/}
                        {/*    </li>*/}
                        {/*    <li style={{*/}
                        {/*        listStyleImage: 'url(/images/check_icon.png)'*/}
                        {/*    }}>Through lii, fans gain unprecedented access to digital collectibles and services,*/}
                        {/*        democratizing content and allowing fans to directly support their favorite celebrities*/}
                        {/*        and*/}
                        {/*        brands with exclusive offerings.*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                    </p>
                </Col>
                <Col className={'d-none d-md-block'}>
                    <img src={'/images/empowerment.png'} className={'img-fluid'}/>
                </Col>
            </Row>
        </Container>
};

export default About;
